import { useNavigate, useParams } from 'react-router-dom'
import AppLayout from '@components/layouts/app'
import { useCallback, useEffect, useRef, useState } from 'react'
import { api } from '@/libraries/api'
import ResultItem from '@/components/partials/result-item'
import moment from 'moment'
import withReactContent from 'sweetalert2-react-content'
import _, { set } from 'lodash'
import Swal from 'sweetalert2'

const outDebounce = (func, delay) => _.debounce(func, delay)

function DetailPage() {
  const params = useParams()
  const [detail, setDetail] = useState({})
  const [timeLoop, setTimeLoop] = useState(null)
  const [progress, setProgress] = useState(0)
  const [isFirst, setIsFirst] = useState(true)
  const navigation = useNavigate()
  const [search, setSearch] = useState('')
  const searchRef = useRef(null)
  const [sugestion, setSugestion] = useState([])

  const handleGetDetail = useCallback(async () => {
    if (isFirst) {
      window.loading.start()
    }
    setIsFirst(false)
    api()
      .get(`/api/search/${params.uuid}`)
      .then(res => {
        setDetail(res.data.data)
        if (res.data.data.search.status !== 'processing') {
          clearInterval(timeLoop)
        }
        window.loading.stop()
      })
      .then(() => {
        window.loading.stop()
      })
  }, [params, isFirst])
  useEffect(() => {
    if (params.uuid) {
      handleGetDetail()
    }
  }, [params.uuid])

  const handleTimeloop = useCallback(() => {
    if (timeLoop) {
      clearInterval(timeLoop)
    }
    setTimeLoop(
      setInterval(() => {
        handleGetDetail()
      }, 2000)
    )

    if (detail?.search?.status !== 'processing') {
      clearInterval(timeLoop)
    }
  }, [timeLoop, detail])

  useEffect(() => {
    if (detail?.search?.status == 'processing') {
      handleTimeloop()
    } else {
      clearInterval(timeLoop)
    }

    setSearch(detail?.search?.question)
  }, [detail])

  useEffect(() => {
    let inrv = setInterval(() => {
      if (detail?.search?.createdAt) {
        const startDate = moment(detail?.search?.createdAt)

        const progressd = moment().diff(startDate, 'seconds')

        if (progressd > 290) {
          setProgress(95)
        } else {
          setProgress((progressd / 300) * 100)
        }
      }

      if (detail?.search?.status !== 'processing') {
        clearInterval(inrv)
      }
    }, 500)
    return () => {
      clearInterval(inrv)
    }
  }, [detail])

  useEffect(() => {
    // outside click searchRef
    const handleClickOutside = e => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setSugestion([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
  }, [])

  const handleSubmit = useCallback(
    async e => {
      // on enter
      if (e?.preventDefault) {
        e.preventDefault()
      }

      let searchQuery = typeof e == 'string' ? e : search

      const MySwal = withReactContent(Swal)
      // if (searchQuery.split(' ')?.length < 3) {
      //   MySwal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Pencarian minimal 3 kata!',
      //   })
      //   return 0
      // }

      // }
      // const inputPass = await MySwal.fire({
      //   title: 'Masukan password untuk melanjutkan',
      //   html: `
      //   <input id="swal-email" class="swal2-input m-0 w-full" placeholder="Masukan email anda" type="email">
      //   <input id="swal-pass" class="swal2-input m-0 w-full mt-4" placeholder="Masukan password"  type="password"  >
      // `,
      //   preConfirm: () => {
      //     return [document.getElementById('swal-email').value, document.getElementById('swal-pass').value]
      //   },
      //   confirmButtonText: 'Lanjutkan',
      //   cancelButtonText: 'Batal',
      //   inputAttributes: {
      //     autocapitalize: 'off',
      //     autocorrect: 'off',
      //   },
      // })

      // if (inputPass.isDismissed) return 0

      const [inputEmail, inputPassword] = []
      // const [inputEmail, inputPassword] = inputPass?.value ?? []

      // check validate email with regex
      // if (!inputEmail || !inputEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || !inputEmail.includes('@')) {
      //   MySwal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Email yang anda masukan tidak valid',
      //   })
      //   return 0
      // }

      // if (inputPassword != 'skripsi') {
      //   MySwal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Password yang anda masukan salah!',
      //   })
      // } else {
      window.loading.start()
      const { data } = await api().post('/api/search/store', {
        search: searchQuery,
        email: inputEmail,
      })
      window.loading.stop()
      if (data?.data?.uuid) {
        navigation(`/${data?.data?.uuid}`)
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Terjadi kesalahan saat memproses data!',
        })
      }
      // }
      window.loading.stop()
    },
    [search]
  )

  const handleFetchSugestion = useCallback(
    outDebounce(async () => {
      const { data } = await api().get('/api/search/sugestion', {
        search: search,
        limit: 5,
      })

      setSugestion(data?.data ?? [])
    }, 300),
    [search]
  )

  useEffect(() => {
    if (search?.length > 3 && search !== detail?.search?.question) {
      handleFetchSugestion()
    } else {
      setSugestion([])
    }
  }, [search, detail])

  const hightlightItem = useCallback(
    text => {
      return text.replace(
        new RegExp(search, 'gi'),
        match => `<span class="bg-primary bg-opacity-20 px-1 rounded-sm  text-primary">${match}</span>`
      )
    },
    [search]
  )

  useEffect(() => {
    searchRef.current.focus()
  }, [searchRef])

  const handleGotoDetail = useCallback(uuid => {
    navigation(`/${uuid}`)
  }, [])

  const [isSpeech, setIsSpeech] = useState(false)

  const speechAnswer = useCallback(() => {
    if (window.speechData) {
      window.speechData.onend = () => {
        window.speechData = null
        setIsSpeech(false)
      }
      window.speechData.cancel()
    }

    if (isSpeech) {
      // kill all speech
      window.speechSynthesis.cancel()
      setIsSpeech(false)

      return false
    }
    const synth = window.speechSynthesis
    // using indonesia
    const utterThis = new SpeechSynthesisUtterance(detail?.search?.answer)
    // mix indonesia and english
    utterThis.lang = 'id-ID'
    synth.speak(utterThis)
    window.speechData = synth

    setIsSpeech(true)
  }, [detail?.search?.answer, isSpeech])

  // on unmount
  useEffect(() => {
    return () => {
      if (window.speechData) {
        window.speechData.cancel()
      }
    }
  }, [])

  // handle on reload
  useEffect(() => {
    if (window.speechData) {
      window.speechData.cancel()
    }
    window.onbeforeunload = () => {
      if (window.speechData) {
        window.speechData.cancel()
      }
    }
  }, [])
  return (
    <AppLayout>
      <div className='bg-[#F7F7F7] w-full flex bg-[url("/assets/images/quran.webp")] bg-cover bg-center '>
        <div className=" bg-[#1a1a1a] w-full bg-opacity-75 pt-[80px] pb-3">
          <div className="container mx-auto px-3">
            <div className="py-5">
              <div className="w-full flex justify-center">
                <img src="/assets/images/logo.svg" className="md:h-[250px] h-[150px] md:w-[250px] w-[150px]" alt="" />
              </div>
              <div className="mt-5">
                <div className="form max-w-[700px] w-full mx-auto">
                  <div className="relative" ref={searchRef}>
                    <label className="w-full relative z-20">
                      <div className="searchbar flex relative items-center justify-center">
                        <input
                          type="text"
                          className="w-full bg-white py-3 text-[16px] px-5  border-none focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50
                          transition-all duration-300 placeholder-top align-text-top
                          resize-none
                          border-2 border-white h-[55px] rounded-[50px] ps-[45px] text-opacity-70"
                          placeholder="Cari atau tanyakan sesuatu ..."
                          defaultValue={detail?.search?.question}
                          onChange={e => setSearch(e.target.value)}
                          value={search}
                          onKeyDown={e => e.key === 'Enter' && handleSubmit(e)}
                        />

                        <i className="ri-search-line absolute left-[15px] transition text-[24px] text-primary"></i>
                      </div>
                    </label>

                    {sugestion?.length > 0 ? (
                      <div className="search absolute top-[60px] left-0 w-full px-5 ">
                        <ul className="bg-white w-100 px-3 rounded  py-1 w-full flex flex-col shadow-md ">
                          {sugestion.map((s, idx) => (
                            <li
                              key={idx}
                              className="cursor-pointer border-b  p-2"
                              dangerouslySetInnerHTML={{ __html: hightlightItem(s.question) }}
                              onClick={() => handleGotoDetail(s.uuid)}
                            ></li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {detail?.search?.status === 'failed' ? (
        <section className="container mx-auto w-full mt-5 pb-5 px-3">
          <div className="max-w-5xl mx-auto">
            <div className="w-100 bg-white mt-5 rounded-xl p-4 flex flex-col">
              <h1 className="text-2xl">Gagal melakukan pencarian, Coba lagi ...</h1>
            </div>
          </div>
        </section>
      ) : (
        <>
          {detail?.search?.status && detail?.search?.status !== 'processing' ? (
            <>
              <section className="container mx-auto w-full mt-5 pb-5 px-3">
                <div className="max-w-5xl mx-auto">
                  <div className="w-100 bg-white mt-5 rounded-xl p-4 flex flex-col shadow relative">
                    <div className="bg-primary text-white rounded-full px-3 py-1  me-auto">Rangkuman</div>

                    <button onClick={speechAnswer} className="absolute top-[15px] right-[15px]">
                      {/* remix icon speaker */}
                      {isSpeech ? (
                        <i className="ri-volume-mute-fill text-primary text-[24px]"></i>
                      ) : (
                        <i className="ri-volume-up-fill text-primary text-[24px]"></i>
                      )}
                    </button>
                    <div className="mt-3  text-justify">{detail?.search?.answer}</div>
                  </div>
                </div>
              </section>

              <section className="container mx-auto w-full mt-5 pb-5 px-3">
                <div className="max-w-5xl mx-auto">
                  <h3 className="text-[24px] font-bold text-primary">Hasil pencarian terkait</h3>
                  <div className="mt-5 flex flex-col gap-5">
                    {detail?.results?.map((r, idx) => (
                      <ResultItem item={r} key={idx} />
                    ))}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <>
              <section className="container mx-auto w-full mt-5 pb-5 px-3">
                <div className="bg-white w-full  max-w-[700px] p-3 shadow-md rounded-md mx-auto pb-4">
                  <h2 className="text-2xl font-bold">Pencarian anda sedang di proses ...</h2>

                  <div className="w-full mt-2  rounded h-[15px] bg-body">
                    <div
                      className="bg-primary h-full rounded flex justify-center items-center overflow-hidden"
                      style={{ width: `${progress}%` }}
                    >
                      <span className="text-[10px] text-center text-white">{progress.toFixed(0)}%</span>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </AppLayout>
  )
}

export default DetailPage
