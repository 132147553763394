import PropTypes from 'prop-types'

function ResultItem({ item }) {
  return (
    <div className="w-full bg-white rounded p-3 shadow-md">
      <div className="py-3 relative">
        {/* <div className="absolute top-0 right-0 border border-primary text-primary rounded-full   h-[30px]  px-3 justify-center items-center text-sm  flex ">
          Skor: &nbsp; <span className="font-bold">{item.data.gptScore}</span>
        </div> */}
        <div>
          {item.data.hightlight !== 'intro-surah' ? (
            <div>
              <p className="font-semibold">Ayat Terkait</p>
              <p className=" text-justify">
                {item.data.surah.transliteration} ({item.data.surah.arabic}) Ayat {item.data.ayah.number}
              </p>
              <p className="text-right text-[24px] font-arabic mt-3  text-justify">{item.data.ayah.arabic}</p>

              <p className="text-[14px] mt-3 opacity-70  text-justify">{item.data.ayah.translation}</p>
            </div>
          ) : (
            ''
          )}
          <div className="py-3">
            <p className="font-bold mb-3">Tafsir Terkait : </p>
            {item.tafsirs
              .filter(tf => tf._id === item.data.tafsir)
              .map((t, tid) => (
                <div key={tid} className="flex flex-col gap-1">
                  <p className="font-semibold text-sm  text-justify">{t.source.description} :</p>
                  <p
                    className="text-[14px] opacity-70   text-justify"
                    dangerouslySetInnerHTML={{
                      __html: t.content,
                    }}
                  ></p>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* {item.data.hightlight !== 'intro-surah' ? (
        <div className="w-100 flex justify-center items-center mb-2">
          <a target='_blank' href={`/search/${item.search.uuid}`} className=" text-primary rounded text-center text-[16px]">
            Lihat Di Kemenag
          </a>
        </div>
      ) : (
        ''
      )} */}
    </div>
  )
}

ResultItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ResultItem
