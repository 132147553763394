import { useCallback, useEffect, useRef, useState } from 'react'
import { api } from '../libraries/api'
import AppLayout from '@components/layouts/app'
import SearchItem from '@components/partials/search-item'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'

const outDebounce = (func, delay) => _.debounce(func, delay)

function Home() {
  const navigation = useNavigate()
  const [latestSearch, setLatestSearch] = useState([])
  const [search, setSearch] = useState('')
  const [isLoad, setIsLoad] = useState(true)
  const [sugestion, setSugestion] = useState([])
  const searchRef = useRef(null)

  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState({
    page: 1,
    total: 0,
    limit: 10,
  })

  const getLatestSearch = useCallback(
    outDebounce(async () => {
      setIsLoad(true)
      const { data } = await api().get('/api/search/latest', {
        page,
        limit: 5,
      })
      setMeta(data?.meta)
      setLatestSearch(data?.data ?? [])
      setIsLoad(false)
    }, 200),
    [page]
  )

  useEffect(() => {
    getLatestSearch()
  }, [page])

  useEffect(() => {
    getLatestSearch()

    // outside click searchRef
    const handleClickOutside = e => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setSugestion([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
  }, [])

  const handleSubmit = useCallback(
    async e => {
      // on enter
      if (e?.preventDefault) {
        e.preventDefault()
      }

      let searchQuery = typeof e == 'string' ? e : search

      const MySwal = withReactContent(Swal)
      // if (searchQuery.split(' ').length < 3) {
      //   MySwal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Pencarian minimal 3 kata!',
      //   })
      //   return 0
      // }

      // }
      // const inputPass = await MySwal.fire({
      //   title: 'Masukan password untuk melanjutkan',
      //   html: `
      //   <input id="swal-email" class="swal2-input m-0 w-full" placeholder="Masukan email anda" type="email">
      //   <input id="swal-pass" class="swal2-input m-0 w-full mt-4" placeholder="Masukan password"  type="password"  >
      // `,
      //   preConfirm: () => {
      //     return [document.getElementById('swal-email').value, document.getElementById('swal-pass').value]
      //   },
      //   confirmButtonText: 'Lanjutkan',
      //   cancelButtonText: 'Batal',
      //   inputAttributes: {
      //     autocapitalize: 'off',
      //     autocorrect: 'off',
      //   },
      // })

      // if (inputPass.isDismissed) return 0

      // const [inputEmail, inputPassword] = inputPass?.value ?? []
      const [inputEmail, inputPassword] = []

      // check validate email with regex
      // if (!inputEmail || !inputEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || !inputEmail.includes('@')) {
      //   MySwal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Email yang anda masukan tidak valid',
      //   })
      //   return 0
      // }

      // if (inputPassword != 'skripsi') {
      //   MySwal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Password yang anda masukan salah!',
      //   })
      // } else {
      window.loading.start()
      const { data } = await api().post('/api/search/store', {
        search: searchQuery,
        email: inputEmail,
      })
      window.loading.stop()
      if (data?.data?.uuid) {
        navigation(`/${data?.data?.uuid}`)
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Terjadi kesalahan saat memproses data!',
        })
      }
      // }
      window.loading.stop()
    },
    [search]
  )

  const handlePushSearch = useCallback(
    async text => {
      setSearch(text)
      handleSubmit(text)
    },
    [search]
  )

  const handleFetchSugestion = useCallback(
    outDebounce(async () => {
      const { data } = await api().get('/api/search/sugestion', {
        search: search,
        limit: 5,
      })

      setSugestion(data?.data ?? [])
    }, 300),
    [search]
  )

  useEffect(() => {
    if (search.length > 3) {
      handleFetchSugestion()
    } else {
      setSugestion([])
    }
  }, [search])

  const hightlightItem = useCallback(
    text => {
      return text.replace(
        new RegExp(search, 'gi'),
        match => `<span class="bg-primary bg-opacity-20 px-1 rounded-sm  text-primary">${match}</span>`
      )
    },
    [search]
  )

  const handleGotoDetail = useCallback(uuid => {
    navigation(`/${uuid}`)
  }, [])

  return (
    <>
      <AppLayout>
        <div className='bg-[#F7F7F7] w-full flex bg-[url("/assets/images/quran.webp")] bg-cover bg-center  relative z-[9]'>
          <div className=" bg-[#1a1a1a] w-full bg-opacity-75 pt-[80px] pb-3">
            <div className="container mx-auto px-3">
              <div className="py-5">
                <div className="w-full flex justify-center">
                  <img src="/assets/images/logo.svg" className="md:h-[250px] h-[150px] md:w-[250px] w-[150px]" alt="" />
                </div>
                <div className="max-w-[700px] rounded-lg bg-white p-4 m-auto flex flex-col">
                  <p className="text-sm md:text-sm opacity-80">
                    Gunakan sistem pencarian tafsir Al-Quran dengan AI untuk mencari tafsir melalui pertanyaan atau kata
                    kunci spesifik, seperti “Tafsir surat Al-Baqarah ayat 183” atau “Tafsir tentang zakat”. Mohon
                    gunakan dengan bijak.
                  </p>
                  <Link to="/about" className="ms-auto mt-3">
                    <button className="text-primary ms-auto  rounded  text-sm hover:opacity-80 transition-all font-bold">
                      Lebih Detail
                      <i className="ms-2 ri-arrow-right-s-line"></i>
                    </button>
                  </Link>
                </div>
                <div className="mt-5">
                  <div className="form max-w-[700px] w-full mx-auto">
                    <div>
                      <div className="relative" ref={searchRef}>
                        <label className="w-full relative z-20">
                          <div className="searchbar flex relative items-center justify-center">
                            <input
                              type="text"
                              className="w-full bg-white py-3 text-[16px] px-5  border-none focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50
                          transition-all duration-300 placeholder-top align-text-top
                          resize-none
                          border-2 border-white h-[55px] rounded-[50px] ps-[45px] text-opacity-70"
                              placeholder="Cari atau tanyakan sesuatu ..."
                              // onFocus={() => setFocus(true)}
                              // onBlur={() => setFocus(false)}
                              value={search}
                              onChange={e => setSearch(e.target.value)}
                              onKeyDown={e => e.key === 'Enter' && handleSubmit(e)}
                            />
                            {/* Pencarian di tutup sementara karena sedang ada perbaikan data. */}
                            <i className="ri-search-line absolute left-[15px] transition text-[24px] text-primary"></i>
                            {/* <button className='bg-transparent absolute right-[15px]'>
                          <i className='ri-send-plane-fill text-[22px] text-primary'></i>
                        </button> */}
                          </div>
                        </label>
                        {sugestion.length > 0 ? (
                          <div className="search absolute top-[60px] left-0 w-full px-5 ">
                            <ul className="bg-white w-100 px-3 rounded  py-1 w-full flex flex-col shadow-md ">
                              {sugestion.map((s, idx) => (
                                <li
                                  key={idx}
                                  className="cursor-pointer border-b  p-2"
                                  dangerouslySetInnerHTML={{ __html: hightlightItem(s.question) }}
                                  onClick={() => handleGotoDetail(s.uuid)}
                                ></li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <ul className="w-100 flex gap-3 mt-4 justify-center flex-wrap  ">
                        <li
                          className="text-white text-sm font-semibold cursor-pointer"
                          onClick={() => handlePushSearch('Nabi Terakhir dalam alquran')}
                        >
                          Nabi Terakhir dalam alquran
                        </li>
                        <li
                          className="text-white text-sm font-semibold cursor-pointer"
                          onClick={() => handlePushSearch('Nabi pertama dalam alquran')}
                        >
                          Nabi pertama dalam alquran
                        </li>
                        <li
                          className="text-white text-sm font-semibold cursor-pointer"
                          onClick={() => handlePushSearch('Hukum melaksanakan puasa')}
                        >
                          Hukum melaksanakan puasa
                        </li>
                        <li
                          className="text-white text-sm font-semibold cursor-pointer"
                          onClick={() => handlePushSearch('Ibu dari nabi isa')}
                        >
                          Ibu dari nabi isa
                        </li>
                      </ul>

                      {/* <div className='w-full transition flex'>
                      <button className='bg-primary text-white mt-5 ms-auto rounded  ps-6 pe-4 py-2 hover:opacity-80 transition-all font-bold'>
                        Cari
                        <i className='ms-2 ri-send-plane-fill'></i>
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="container mx-auto w-full mt-5 pb-5 px-3">
          <div className="max-w-5xl mx-auto">
            {isLoad ? (
              <div className="w-full flex justify-center items-center flex-col py-5">
                <span className="text-xl font-bold">Memuat Data ... </span>
                <img src="/assets/images/loading.svg" alt="" className="h-[80px] mt-2" />
              </div>
            ) : (
              <>
                <h3 className="text-[24px] font-bold text-primary">Pencarian pengguna terakhir</h3>
                <div className="mt-5 flex flex-col gap-5">
                  {latestSearch.map((r, idx) => (
                    <SearchItem item={r} key={idx} />
                  ))}
                </div>
              </>
            )}
          </div>
        </section>

        <nav aria-label="Page navigation " className="py-3 w-full  flex justify-center ">
          <ul className="inline-flex -space-x-px text-sm">
            <li>
              <button
                onClick={() => setPage(page - 1)}
                className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
              >
                <i className="ri-arrow-left-line mr-1"></i>
                Prev
              </button>
            </li>
            {_.range(1, meta.total / 5).map((p, idx) => (
              <li key={idx}>
                <button
                  onClick={() => setPage(p)}
                  className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    page == p ? 'bg-primary text-white' : 'text-gray-500 bg-white'
                  } border border-gray-300 `}
                >
                  {p}
                </button>
              </li>
            ))}
            <li>
              <button
                onClick={() => setPage(page + 1)}
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
              >
                Next
                <i className="ri-arrow-right-line ml-1"></i>
              </button>
            </li>
          </ul>
        </nav>

        {/* pagination */}
      </AppLayout>
    </>
  )
}

export default Home
