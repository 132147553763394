import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/css/app.css'
import routes from './route.jsx'
import { RouterProvider } from 'react-router-dom'
import 'remixicon/fonts/remixicon.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <main className="body-bg min-h-screen ">
      <RouterProvider router={routes} />
    </main>
  </React.StrictMode>
)
