import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function AppLayout({ children, bgClass }) {
  const [isScroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    })

    setScroll(window.scrollY > 100)
  }, [])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    window.loading = {}
    window.loading.start = () => {
      setLoading(true)
    }
    window.loading.stop = () => {
      setLoading(false)
    }
  }, [])
  return (
    <main className={`min-h-screen flex flex-col ${bgClass ?? ''}`}>
      <header
        className={`w-full bg-danger px-3 fixed top-0 left-0 ${
          isScroll ? 'bg-white shadow-md' : 'bg-transparent'
        } transition-all z-50  duration-300`}
      >
        <div className={`container transition-all duration-300 mx-auto flex ${isScroll ? 'py-3' : 'py-4'}`}>
          <Link to="/">
            <img
              src="/assets/images/logo-horizontal.svg"
              alt=""
              className={`transition-all duration-300 ${isScroll ? 'h-[35px]' : 'brightness-0 invert h-[40px]'}`}
            />
          </Link>

          <div className="ms-auto flex items-center">
            <ul>
              <li>
                <Link to="/about" className={`cursor-pointer ${isScroll ? ' text-primary' : 'text-white'}`}>
                  Tentang Aplikasi
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {children}

      <footer className="footer bg-white mt-auto">
        <div className="container mx-auto px-3 py-5">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500">©{moment().format('YYYY')} Skripsi Alquran Digital </p>
            </div>
          </div>
        </div>
      </footer>

      {loading && (
        <div className="h-screen w-screen fixed top-0 left-0 z-50 bg-black bg-opacity-80 flex justify-center items-center flex-col">
          <div className="flex justify-center items-center flex-col">
            <p className="text-center text-xl text-white">Loading ...</p>
            <img src="/assets/images/loading.svg" alt="" className="h-[80px] mt-2" />
          </div>
        </div>
      )}
    </main>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bgClass: PropTypes.string,
}

export default AppLayout
