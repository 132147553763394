import AppLayout from '@/components/layouts/app'

function About() {
  return (
    <>
      <AppLayout bgClass="bg-white">
        <div className='bg-[#F7F7F7] w-full flex bg-[url("/assets/images/quran.webp")] bg-cover bg-center  relative z-[9]'>
          <div className=" bg-[#1a1a1a] w-full bg-opacity-75 pt-[80px] pb-3">
            <div className="container mx-auto px-3">
              <div className="py-5 flex pb-[50px]">
                <h1 className="text-[35px] font-bold text-white mx-auto">Tentang Aplikasi</h1>
              </div>
            </div>
          </div>
        </div>

        <section id="about" className="container">
          <div className="max-w-4xl mx-auto px-4 px-md-0">
            <div className="w-100">
              <h2 className="text-primary text-xl font-bold my-4">Pengembangan</h2>

              <p className="opacity-70 text-justify">
                Proyek skripsi berjudul &quot; Implementasi Pencarian Semantik dalam Tafsir Al-Quran dengan Algoritma
                Cosine Similarity dan Large Language Models &quot;. Proyek ini merupakan hasil penelitian yang dilakukan
                untuk menyelesaikan program studi Strata 1 (S1) di bidang Teknik Informatika Universitas Nusa Putra.
                Tujuan utama dari penelitian ini adalah mengembangkan sebuah sistem pencarian semantik yang dapat
                membantu dalam memahami dan menginterpretasikan tafsir Al-Quran secara lebih mendalam dan akurat.
              </p>
            </div>
            <div className="w-100 mt-6">
              <h2 className="text-primary text-xl font-bold my-4">Latar Belakang</h2>
              <p className="opacity-70 text-justify">
                Tafsir Al-Quran adalah penjelasan atau interpretasi dari teks Al-Quran yang bertujuan untuk memahami
                makna yang terkandung di dalamnya. Seiring dengan perkembangan teknologi, kebutuhan untuk mengakses
                tafsir secara cepat dan efisien semakin meningkat. Salah satu cara untuk mencapai ini adalah dengan
                mengimplementasikan teknologi pencarian semantik. Teknologi ini memungkinkan pengguna untuk menemukan
                informasi yang relevan berdasarkan makna atau konteks, bukan hanya berdasarkan kata kunci yang spesifik.
              </p>
            </div>
            <div className="w-100 mt-6">
              <h2 className="text-primary text-xl font-bold my-4">Metodologi</h2>
              <p className="opacity-70 text-justify">
                Penelitian ini menggunakan dua pendekatan utama dalam pengembangan sistem pencarian semantik:
              </p>
              <ul className="mt-4 list-decimal pl-6 opacity-70">
                <li className="text-justify">
                  Algoritma Cosine Similarity: Algoritma ini digunakan untuk mengukur kesamaan antara dua teks
                  berdasarkan sudut kosinus dari vektor kata-kata dalam teks tersebut. Dengan algoritma ini, sistem
                  dapat menentukan seberapa mirip dua kalimat atau paragraf dalam tafsir Al-Quran.
                </li>
                <li className="text-justify">
                  Large Language Models (LLMs): LLMs seperti BERT, GPT, atau model bahasa lainnya yang telah dilatih
                  secara ekstensif pada berbagai teks digunakan untuk menangkap konteks dan makna yang lebih dalam dari
                  teks Al-Quran dan tafsirnya. Model ini dapat membantu dalam memahami konteks yang lebih luas dan
                  mengidentifikasi hubungan semantik yang mungkin tidak terdeteksi oleh algoritma tradisional.
                </li>
              </ul>
            </div>

            <div className="w-100 mt-6">
              <h2 className="text-primary text-xl font-bold my-4">Implementasi</h2>
              <p className="opacity-70 text-justify">
                Proyek ini melibatkan beberapa tahap implementasi yang meliputi:
              </p>
              <ul className="mt-4 list-decimal pl-6 opacity-70">
                <li className="text-justify">
                  Pengumpulan dan Pra-pemrosesan Data: Mengumpulkan berbagai sumber tafsir Al-Quran dan melakukan
                  pra-pemrosesan untuk menyiapkan data yang akan digunakan oleh algoritma pencarian.
                </li>
                <li className="text-justify">
                  Pengembangan Algoritma Pencarian: Mengembangkan algoritma pencarian menggunakan Cosine Similarity dan
                  mengintegrasikan Large Language Models untuk meningkatkan akurasi pencarian.
                </li>
                <li className="text-justify">
                  Pengujian dan Evaluasi: Melakukan pengujian terhadap sistem yang dikembangkan dengan berbagai skenario
                  pencarian untuk memastikan keakuratan dan efisiensi sistem.
                </li>
              </ul>
            </div>

            <div className="w-100 mt-6">
              <h2 className="text-primary text-xl font-bold my-4">Hasil dan Kontribusi</h2>

              <div className="flex flex-row">
                <img src="/assets/images/f1.png" className="w-[50%]" alt="" />
                <img src="/assets/images/roc.png" className="w-[50%]" alt="" />
              </div>
              <p className="opacity-70 text-justify mt-4">
                Sistem yang dihasilkan dari penelitian ini diharapkan dapat memberikan kontribusi signifikan dalam
                bidang studi Al-Quran dan teknologi pencarian semantik. Dengan kemampuan untuk melakukan pencarian
                berdasarkan konteks dan makna, sistem ini dapat membantu para peneliti, pelajar, dan masyarakat umum
                dalam mengakses dan memahami tafsir Al-Quran dengan lebih baik. Berdasarkan hasil evaluasi model dengan
                menggunakan F1-Score Vs Threshold dan ROC-AUC Score. F1-Score memberikan nilai 0,819 dan untuk Threshold
                0,410 semntara itu hasil dari ROC-AUC Score 0,587 Berdasarkan hasil pengujian model menunjukan akurasi
                ….% dan untuk pengujian dengan user atau pengguna menunjukan akurasi …..%.
              </p>
            </div>

            <div className="w-100 mt-5 mb-5">
              <h2 className="text-primary text-xl font-bold my-4">Penutup</h2>
              <p className="opacity-70 text-justify">
                Proyek ini adalah langkah kecil menuju pemanfaatan teknologi canggih dalam studi keagamaan. Kami
                berharap hasil dari penelitian ini dapat menjadi dasar untuk pengembangan lebih lanjut dan memberikan
                manfaat nyata bagi masyarakat luas. Terima kasih telah mengunjungi halaman ini dan menunjukkan minat
                pada penelitian kami. Untuk informasi lebih lanjut atau pertanyaan, silakan hubungi kami melalui
                <a href="mailto:tofik.hidayat_ti20@nusaputra.ac.id" className="text-primary ms-2">
                  tofik.hidayat_ti20@nusaputra.ac.id
                </a>{' '}
                /{' '}
                <a href="mailto:aziz.hidayat_ti22@nusaputra.ac.id" className="text-primary">
                  aziz.hidayat_ti22@nusaputra.ac.id
                </a>
              </p>
            </div>
          </div>
        </section>
      </AppLayout>
    </>
  )
}

export default About
