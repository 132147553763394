import { createBrowserRouter } from 'react-router-dom'
import Home from './pages/home'
import NotFound from './pages/404'
import About from './pages/about'
import DetailPage from './pages/detail'

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/:uuid',
    element: <DetailPage />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
])

export default routes
