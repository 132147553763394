import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'

moment.locale('id')

function SearchItem({ item }) {
  return (
    <Link to={`/${item.search.uuid}`} key={item.search.uuid}>
      <div className="w-full bg-white rounded p-3 shadow-md">
        <div className="bg-primary bg-opacity-30 p-3 rounded">
          <div className="flex items-start">
            <p className="font-bold">Pertanyaan:</p>
            <div className="ms-auto bg-green-400 text-white flex rounded-full px-3 py-1 text-xs items-center">
              {_.startCase(item.search.status)}
            </div>
          </div>
          <div>
            <h3 className="opacity-70">{item.search.question}</h3>
          </div>
          <p className="font-bold mt-3 ">Hasil Terkait:</p>
          <p
            dangerouslySetInnerHTML={{
              __html: _.truncate(item.search.answer, {
                length: 200,
              }),
            }}
            className=" text-justify"
          ></p>
        </div>
        <div className="py-3">
          <p className="font-semibold">Ayat Terkait</p>

          {item.results.map((c, cid) => (
            <div key={cid}>
              <div>
                <p>
                  {c.surah.transliteration} ({c.surah.arabic}) Ayat {c.ayah.number}
                </p>
                <p className="text-right text-[24px] font-arabic mt-2">{c.ayah.arabic}</p>
                <p className="font-bold mb-2 mt-4">Terjemahan : </p>
                <p className="text-[14px] opacity-70  text-justify">{c.ayah.translation}</p>
              </div>
              <div className="py-3">
                <p className="font-bold mb-3">Tafsir Terkait : </p>
                {c.tafsirs
                  .filter(tf => tf._id == c.result.tafsir)
                  .map((t, tid) => (
                    <div key={tid} className="flex flex-col gap-1">
                      <p className="font-semibold text-sm">{t.source.description}</p>
                      <p
                        className="text-[14px] opacity-70  text-justify"
                        dangerouslySetInnerHTML={{
                          __html: _.truncate(t.content, {
                            length: 200,
                          }),
                        }}
                      ></p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div className="w-100 flex justify-center items-center mb-2 flex-col md:flex-row">
          <span className="text-xs text-primary rounded-sm  ms-auto md:ms-0">
            {_.upperFirst(moment(item.search?.createdAt).fromNow())}
            {item.search?.email && (
              <span className="ms-2 text-black text-opacity-50">(&nbsp;{item.search.email} &nbsp;)</span>
            )}
          </span>
          <a
            href={`/search/${item.search.uuid}`}
            className=" text-primary ms-auto  rounded text-center text-[16px] flex items-center"
          >
            <span className="text-sm">Lihat Detail jawaban</span> <i className="ri-arrow-right-s-line"></i>
          </a>
        </div>
      </div>
    </Link>
  )
}

SearchItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default SearchItem
