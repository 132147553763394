import axios from 'axios';
import axiosRetry from 'axios-retry';
import { create } from 'apisauce';
// import JSCookie from 'js-cookie';

export const api = () => {
  //   const COOKIE_TOKEN = import.meta.env.VITE_COOKIE_TOKEN || '';
  const BASE_URL = import.meta.env.VITE_API_BASE_URL || '';

  //   const authCookie = JSCookie.get(COOKIE_TOKEN);
  const baseApi = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      //   Authorization: `Bearer ${authCookie}`,
    },
  });

  axiosRetry(baseApi, { retryDelay: axiosRetry.exponentialDelay });
  return create({
    baseURL: BASE_URL,
    axiosInstance: baseApi,
    timeout: 20000,
  });
};
